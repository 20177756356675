* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
    font-family: 'Cormorant Garamond', serif !important;
    font-weight: 400;
    font-size: 20px;
    color: white;
    background: rgb(12, 34, 132);
    background: linear-gradient(90deg, rgb(12, 34, 132) 0%, rgb(123, 142, 221) 100%);
}

a {
    text-decoration: none !important;
}

h1 {
    font-family: 'Bebas Neue';
    font-size: 7vw;
    text-align: center !important;
}

@media (max-width: 425px) {
    h1 {
        font-size: 10vw;
    }
}

h2 {
    font-family: 'Bebas Neue';
    font-size: 6vh;
    text-align: center !important;
}

h3 {
    font-family: 'Bebas Neue';
    font-size: 5vh;
    text-align: center !important;
}

h4,
h5 {
    font-family: 'Bebas Neue';
    font-size: 2.5vw;
}

p {
    font-size: 1.35vw;
}


.navbar {
    z-index: 100;
    background: white !important;
}

.navbar-brand {
    font-family: 'Bebas Neue';
    font-size: xx-large;
    padding-bottom: 0 !important;
}

.nav-item {
    text-decoration: none !important;
}

@media (1199px < width < 1259px) {
    .nav-item {
        text-align: center;
    }
}

.nav-link:hover,
.dropdown-item:hover {
    color: white !important;
    background: rgb(68, 93, 180) !important;
}


@media (min-width: 992px) {
    .dropdown:hover .nav-dropdown-menu {
        display: block !important;
        margin-top: 0 !important;
    }
}


.dropdown-menu {
    padding-bottom: 0;
    padding-top: 0;
}

.fullpage {
    min-height: 100vh;
}

#popup {
    position: fixed;
    z-index: 1000;
    height: 110vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(74, 74, 74, 0.59);
    top: -10vh;
}

.popup-close-btn {
    color: white;
    width: fit-content;
    background: rgb(204, 47, 47);
    position: absolute;
    right: 12vw;
    top: 22vh;
}

.concert-ad {
    width: 80vw;
    height: 80vh;
    margin-top: 10vh;
    border: .25vw solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    box-shadow: rgb(208, 208, 208) 0px 20px 30px -10px;
}

.popup-logo {
    border: 2px solid white;
}

.home-cover {
    height: 50vh;
    width: 100%;
    object-fit: cover;
}

.hero-image {
    height: 50vh;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 5vh;
    mask-image: linear-gradient(to top, transparent 0%, black 75%);
}

.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.overlay-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero-title {
    position: absolute !important;
    height: 50%;
    width: 100%;
    top: 8vh;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.showfest {
    margin-top: 2vh;
    margin-bottom: 20vh;
}

.showfest-btns,
.popup-btns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.showfest-logo,
.popup-logo {
    width: 100%;
}

.poster-ticket-click {
    pointer-events: none;
}

.kidscamp {
    margin-top: 15vh;
    text-align: center;
}

.kidscamp-title1 h2 {
    font-size: 7vw !important;
}

.kidscamp-title2 {
    display: none;
}

.kidscamp-buttons {
    display: flex;
    justify-content: space-around;
}

.home-featured-IMG-div {
    min-height: 80vh;
    margin-bottom: 10vh;
    margin-top: -5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 1024px) {
    .featured-IMG-1,
    .featured-IMG-2,
    .featured-IMG-3 {
        width: 60vw !important;
    }
}

@media (max-width: 500px) {
    .home-featured-IMG-div {
        min-height: 60vh;
    }

    .featured-IMG-1,
    .featured-IMG-2,
    .featured-IMG-3 {
        width: 80vw !important;
    }
}

.featured-IMG-1,
.featured-IMG-2,
.featured-IMG-3 {
    display: flex;
    flex-direction: column;
    width: 40vw;
    background: black;
    color: white;
}

.FeaturedIMG {
    width: 100%;
}

.featured-IMG-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: featured-IMG-1 18s infinite;
}

@keyframes featured-IMG-1 {
    0% {
        opacity: 0;
    }

    3% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    33% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.featured-IMG-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: featured-IMG-2 18s infinite;
}

@keyframes featured-IMG-2 {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    63% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.featured-IMG-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: featured-IMG-3 18s infinite;
}

@keyframes featured-IMG-3 {
    0% {
        opacity: 0;
    }

    63% {
        opacity: 0;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.testimonial-title-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35%;
    height: 25%;
    margin-bottom: 5%;
}

.testimonial-title-card img {
    width: 60px;
    margin: 5px;
    border-radius: 50%;
}

.btn {
    background: white !important;
    color: rgb(12, 34, 132) !important;
    transition: transform 0.3s ease-out;
    min-width: fit-content !important;
}

.btn:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in;
}

.fa-brands {
    font-size: 50px;
    color: white;
}

.fa-brands:hover {
    color: rgb(246, 213, 22)
}

.featured {
    margin-top: 10%;
    margin-bottom: 10%;
}

.social {
    width: 80vw;
    height: 30vh;
    margin: auto;
}

.social-icon {
    margin-left: 2vw;
    margin-right: 2vw;
}

.payment-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 5vh;
    padding-bottom: 20vh;
}

.gold-text {
    color: gold;
}

.payment-btn {
    font-size: 2rem;
    margin-bottom: 5vh;
    width: fit-content;
}

.sponsor-thank-you {
    width: 80vw;
    margin: auto;
}

.sponsors {
    height: 65vh;
    width: 100%;
    position: relative;
}

.sponsors h4,
.sponsors h5 {
    font-family: 'Cormorant Garamond', serif !important;
    font-size: 1.75vw;
}

.sponsor-img-div {
    width: 15vw;
    margin: auto;
}

.sponsor1-img-div {
    width: 25vw;
}

.sponsor-img {
    width: 75%;
    height: 100%;
}

.sponsors1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: sponsors1 18s infinite;
}

@keyframes sponsors1 {
    0% {
        opacity: 0;
    }

    3% {
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    33% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.sponsors2 {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: sponsors2 18s infinite;
}

@keyframes sponsors2 {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    63% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.sponsors3 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    animation: sponsors3 18s infinite;
}

@keyframes sponsors3 {
    0% {
        opacity: 0;
    }

    63% {
        opacity: 0;
    }

    66% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.sponsor-col {
    width: 40%;
}

.sponsor-col-2 {
    width: 40vw;
}

.sponsor-toomanyIMG {
    display: flex;
    justify-content: space-around;
}

.footer {
    background: rgb(245, 245, 245);
    min-height: 100px;
    color: rgb(12, 34, 132) !important;
    mask-image: linear-gradient(to bottom, transparent 0%, black 75%);
}

.footer a {
    color: rgb(12, 34, 132) !important;
}

.footer a:hover {
    color: gold !important;
}

iframe {
    z-index: 10;
    width: 80vw !important;
    height: 80vh !important;
    animation: calendar 2s;
    margin-top: 10vh !important;
    margin-bottom: 2vh;
    background-color: black;
    padding: 0 !important;
}

.purchase-div {
    display: flex;
    justify-content: center;
}


@keyframes calendar {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.calendar-load {
    position: absolute;
    bottom: 50vh;
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
}

.subscribe-btn {
    position: relative;
    text-align: center;
}


.offcanvas {
    height: 70vh;
}

.offcanvas-last {
    padding-bottom: 20px !important;
}

.offcanvas-body,
.offcanvas-header {
    text-align: center !important;
    color: black !important;
}

.directorIMG {
    border-radius: 50%;
    width: 40%;
}

.overlay-cover-musical {
    background: rgb(134, 187, 220);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cover-img-musical {
    object-fit: cover;
}

.hero-image-musical {
    height: 65vh;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    margin-bottom: 5vh;
    mask-image: linear-gradient(to top, transparent 0%, black 35%);
}

.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}


@media (max-width: 1024px) {

    .hero-image,
    .hero-image-musical {
        height: 30vh;
    }

    .hero-title {
        position: relative !important;
        top: -25vh;
        margin-bottom: -10vh;
    }

    p {
        font-size: medium;
    }

    .showfest {
        margin-top: 0 !important;
    }

    .kidscamp-buttons {
        display: block;
    }

    .kidscamp .btn {
        display: block;
        margin: auto;
        width: 40%;
        margin-top: 2vh;
    }

    .TrunkorTreatIMGDiv {
        width: 30vw;
    }
}

@media (max-width: 768px) {
    .featured {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .showfest-img {
        width: 50%;
        margin-bottom: 5vh;
    }

    .showfest-info {
        width: 80%;
    }

    .sponsor-img-div {
        width: 15vw;
        margin-bottom: 3vh;
    }

    .sponsor1-img-div {
        width: 40vw;
    }

    .sponsors h4,
    {
    font-size: 3vw;
}

.sponsors h4,
.sponsors h5 {
    font-size: 3vw !important;
}

.payment-btn {
    font-size: 1rem;
}

.musical-home {
    display: flex;
    flex-wrap: wrap-reverse;
}
}


@media (max-width: 425px) {

    .showfest-img {
        width: 80%;
        margin-bottom: 5vh;
    }

    .popup-img {
        width: 80%;
        margin-bottom: -5vh;
    }

    .showfest-info,
    .popup-info {
        width: 80%;
    }

    .popup-info {
        font-size: 2rem;
    }

    .kidscamp {
        margin-bottom: 15vh;
    }

    .kidscamp-title1 {
        display: none;
    }

    .kidscamp-title2 {
        display: block;
        width: 80%;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }

    .kidscamp-info,
    .TrunkorTreatIMGDiv {
        width: 80vw;
    }

    .poster-ticket-click {
        pointer-events: all;
    }


    h3 {
        font-size: 1.5em;
    }

    h4,
    h5 {
        font-size: 1.25em;
    }

    .sponsors {
        height: 65vh;
    }

    .sponsors h4,
    .sponsors h5 {
        font-size: 4vw !important;
    }

    .featured {
        width: 90%;
    }

    .bio {
        width: 100%;
    }

    .directorIMG {
        width: 80%;
    }

    .sponsors2 {
        display: flex;
        flex-wrap: wrap;
    }

    .sponsor-col {
        margin-right: 2vw;
        margin-left: 2vw;
    }

    .sponsor-col-2 {
        width: 80vw;
        margin: auto;
    }

    .sponsors2 .sponsor-img-div {
        width: 30vw;
    }

    .sponsor1-img-div {
        width: 50vw;
    }

    .sponsors3 {
        display: flex;
        flex-wrap: wrap;
    }

    .sponsor-toomanyIMG {
        display: block;
    }

    .payment-inputs {
        display: block;
    }

    .payment-btn {
        font-size: 1rem;
    }

    .footer {
        display: block;
        text-align: center;
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

}